/* purgecss ignore */

.default-input {
  @apply block text-neutral-6 font-bold mb-2 w-full;
}

/* The switchButton - the box around the slider */
.switchButton {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switchButton input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.switchButton .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switchButton .slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switchButton input:checked + .slider {
  background-color: #2196f3;
}

.switchButton input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.switchButton input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.switchButton .slider.round {
  border-radius: 34px;
}

.switchButton .slider.round:before {
  border-radius: 50%;
}

.upload-file-form .preview {
  border: 1px solid #c1c1c1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  z-index: 2;
}
.upload-file-form input {
  position: relative;
  outline: 2px dashed black;
  outline-offset: -10px;
  -webkit-transition:
    outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition:
    outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  padding: 120px 0px 85px 35%;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
}

.upload-file-form input:focus {
  outline: 2px dashed black;
  outline-offset: -10px;
  -webkit-transition:
    outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition:
    outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  border: 1px solid black;
}

.upload-file-form {
  position: relative;
}

.upload-file-form:after {
  pointer-events: none;
  position: absolute;
  top: 50px;
  left: 0;
  width: 50px;
  right: 0;
  height: 56px;
  content: '';
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: 1;
}

.upload-file-form:after {
  background-image: url('../images/png/drop.png');
}
.upload-file-form.uploading:after {
  background-image: url('../images/png/reload.png');
}
.upload-file-form.success:after {
  background-image: none;
}
.upload-file-form.error:after {
  background-image: url('../images/png/close.png');
}

.upload-file-form:before {
  position: absolute;
  bottom: 10px;
  left: 0;
  pointer-events: none;
  width: 100%;
  right: 0;
  height: 57px;
  content: 'Click on the button or drag & drop your file here';
  display: block;
  margin: 0 auto;
  color: black;
  font-weight: 600;
  text-align: center;
}
.upload-file-form.uploading:before {
  content: 'upload in progress...';
}
.upload-file-form.success:before {
  content: 'Upload completed';
  color: #008000;
}
.upload-file-form.error:before {
  content: 'You can only upload image';
  color: #ff0000;
}

.formula .default-input {
  display: flex;
  align-items: center;
  width: 70%;
  margin: 0;
  font-size: 0.92rem;
  padding: 0.375rem;
}

.formula .w-1\/4 {
  width: 25%;
}

.formula .px-1\.5 {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}

.formula .border-black {
  border-color: rgba(0, 0, 0, 1);
}

.formula .rounded-none {
  border-radius: 0px;
}

.formula .py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.formula input::-webkit-outer-spin-button,
.formula input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.formula input[type='number'] {
  -moz-appearance: textfield;
}

.formula select {
  appearance: none;
}

.formula select::-ms-expand {
  display: none;
}

.formula .result input {
  width: 100%;
}

.formula .result .default-input {
  justify-content: center;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
