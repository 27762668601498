@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.width-transition {
  transition: width 0.3s ease-out;
}

circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.scale {
  transition: transform 0.2s ease-out;
}

.scale:hover {
  transform: scale(1.05);
}
