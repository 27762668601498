$breakpoint-large: 1300px;
$breakpoint-medium: 900px;
$breakpoint-special: 768px;
$breakpoint-mid-large: 1600px;



@mixin large-only {
    @media screen and (min-width: calc($breakpoint-large + 1px)) {
        @content;
    }
}

@mixin medium-only {
    @media screen and (max-width: $breakpoint-large) and (min-width: calc($breakpoint-medium + 1px)) {
        @content;
    }
}

@mixin small-only {
    @media screen and (max-width: $breakpoint-medium) {
        @content;
    }
}

@mixin medium-large-only {
    @media screen and (max-width: $breakpoint-large) and (min-width: calc($breakpoint-medium + 201px)) {
        @content;
    }
}

@mixin medium-small-only {
    @media screen and (max-width: calc($breakpoint-medium + 201px)) and (min-width: $breakpoint-medium) {
        @content;
    }
}

@mixin small-and-medium-only {
    @media screen and (max-width: $breakpoint-large) {
        @content;
    }
}

@mixin medium-and-large-only {
    @media screen and (min-width: calc($breakpoint-medium + 1px)) {
        @content;
    }
}
@mixin small-special-breakpoint {
    @media screen and (max-width: $breakpoint-special) {
        @content;
    }
}

@mixin medium-and-large-special-breakpoint {
    @media screen and (min-width: $breakpoint-special) {
        @content;
    }
}
@mixin mid-large-only {
    @media screen and (min-width: calc($breakpoint-large + 1px)) and (max-width: $breakpoint-mid-large) {
        @content;
    }
}

@mixin extra-large-only {
    @media screen and (min-width: calc($breakpoint-mid-large + 1px)) and (max-width: 1900px) {
        @content;
    }
}

@mixin extra-large-exluded {
    @media screen and (max-width: $breakpoint-mid-large) {
        @content;
    }
}

@mixin mid-small-only {
    @media screen and (min-width: 600px) and (max-width: $breakpoint-medium ) {
        @content;
    }
}
