.ago-default-color-icon {
  color: #fff !important;
}

.children-w-full {
  display: flex;
  width: 100%;
}

.children-w-full > * {
  flex: 1;
  justify-content: center;
  align-items: center;
}

.ml-10 {
  margin-left: 2.5rem;
}

.max-w-filter-modal {
  max-width: 450px;
}

.max-h-16 {
  max-height: 4rem;
}

.bottom-0 {
  bottom: 0;
}

.w-1-3 {
  width: 33.333%;
}

.w-1-4 {
  width: 25%;
}

.w-1-2 {
  width: 50%;
}

.w-2-3 {
  width: 66.666%;
}

.w-3-4 {
  width: 75%;
}
.w-2-5 {
  width: 40%;
}

.border-gray-500 {
  border-color: #a0aec0;
}

.m-auto {
  margin: auto;
}

.disable-click {
  pointer-events: none;
  cursor: default;
}

.notif-bell {
  position: absolute;
  right: -6px;
  bottom: -6px;
  background-color: #ef0020;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notif-drop {
  position: absolute;
  background-color: red;
  width: 370px;
  right: -5px;
  top: 120%;
}

.text-2xs {
  font-size: 0.5rem;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.w-fit {
  width: fit-content;
}

.ago-container {
  padding-left: 6rem;
  padding-right: 6rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;

  @media (min-width: 640px) {
    max-width: 640px;
  }

  @media (min-width: 768px) {
    max-width: 768px;
  }

  @media (min-width: 1024px) {
    max-width: 1024px;
  }

  @media (min-width: 1280px) {
    max-width: 1280px;
  }

  @media (min-width: 1536px) {
    max-width: 1536px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px !important;
  }
}
