/* custom style */

.main-content {
  background: var(--neutral-colors-gray-100, #f6f8f9);
}

.flex-container {
  display: flex;
  flex-direction: row;
  width: 99%;
  margin: auto;
  padding: 15px;
  gap: 10px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-grow: 1;
}

.row-3 {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.col {
  display: flex;
  box-sizing: content-box;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  /* padding-top: 10px !important;*/
  font-size: 14px;
}

.col1 {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  padding-left: 11px;
  padding-right: 12px;
}

.col2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  gap: 10px;
}
.col3 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  gap: 10px;
}

.col1-item {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: space-around;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  width: 125px;
  height: 30%;
  color: #000000;
  padding-top: 22px;
}

.col2-item {
  width: 100%;
  color: #6a7886;
  line-height: 56px;
  text-align: center;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  font-weight: 800;
  width: 190px;
  height: 70px;
}

.col3-item {
  width: 100%;
  line-height: 14px;
  align-content: center;
  text-align: center;
  background-color: #bbdefb;
  color: #6a7886;
  padding-top: 20px;
  padding-bottom: 2px;
  height: 70px;
}
.dark-blue-transparent-col {
  position: absolute;
  z-index: 999;
  transform: rotate(-90deg);
  top: 155px;
  left: -155px;
  height: 80px;
  width: 390px;
  background-color: #00518c61;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-grow: 1;
  gap: 20px;
  line-height: 22px;
  color: #000000;
}

.transparent-col-dotted {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-grow: 1;
  gap: 20px;
  line-height: 22px;
  color: rgb(0, 0, 0);
  border: 2px dotted #00518c;
  background-color: transparent;
}
.dotted-span {
  font-size: 11px;
  color: gray;
  font-weight: bold;
}

.dotted-col1 {
  left: 8.8rem;
  top: 1rem;
  height: 196px;
  width: 229px;
}
.dotted-span-col1 {
  margin-right: 2%;
  margin-top: -93%;
}

.dotted-col2 {
  left: 23.3rem;
  top: 62px;
  height: 104px;
  width: 229px;
}
.dotted-span-col2 {
  margin-right: 2%;
  margin-top: -52%;
}

.dotted-col3 {
  left: 36.5rem;
  top: 23px;
  height: 103px;
  width: 309px;
}
.dotted-span-col3 {
  margin-right: 27%;
  margin-top: -39%;
}

.vertical-row {
  text-align: end;
  justify-content: center;
  line-height: normal;
}

.row-span {
  font-size: 11px;
  color: #000000;
}

.dotted-area {
  padding-left: 85%;
  margin-top: -95%;
  font-size: 11px;
  color: gray;
  font-weight: bold;
}
.pl-150 {
  padding-left: 150px;
}

.border-x {
  border: 1px solid tomato;
}

.floating-row {
  margin-left: -5.8rem;
  width: 38.75rem;
  height: 8.938rem;
  top: 21rem;
  left: 10.438rem;
  z-index: 9;
  background: #90bed79d;
  transform: rotate(270deg);
}
.floating-row-1 {
  margin-left: -6.6rem;
  z-index: 99;
  width: 23.2rem;
  height: 10.4rem;
  left: 28.8rem;
  top: 14.3rem;
  padding-top: 0;
  background: transparent;
  border: 2px dotted #00518c;
  transform: rotate(270deg);
}
.floating-row-2 {
  margin-left: -6.6rem;
  z-index: 99;
  width: 23.2rem;
  height: 10.4rem;
  left: 43.5rem;
  top: 14.3rem;
  padding-top: 0;
  background: transparent;
  border: 2px dotted #00518c;
  transform: rotate(270deg);
}
.floating-row-1 > div > span {
  text-align: start;
  margin-top: -1.5rem;
  margin-left: 17.4rem;
}
.floating-row-2 > div > span {
  text-align: start;
  margin-top: -1.5rem;
  margin-left: 15.1rem;
}
.floating-row-3 > div > span {
  text-align: start;
  margin-top: -1.5rem;
  margin-left: 17.8rem;
}
.floating-row-3 {
  margin-left: -19.4rem;
  z-index: 99;
  left: 67.2rem;
  height: 19.6rem;
  width: 39rem;
  top: 9.7rem;
  padding-top: 0px;
  background: transparent;
  border: 2px dotted #00518c;
  transform: rotate(270deg);
}
/* colors */

.light-gray {
  background-color: #efefef;
}
.light-blue {
  background-color: #d1f0fd;
}
.light-blue-2 {
  background-color: #a5e4ff;
}
.dark-blue {
  background-color: #00518c;
}
.dark-gray {
  cursor: not-allowed;
  background-color: #9eadb8;
}
.text-dark-blue {
  color: #00518c;
}
.text-light-blue {
  color: #a3c7e8;
}

.text-light-blue-2 {
  color: #a5e4ff;
}
.text-gray {
  color: #6a7886;
}
.text-black {
  color: #000000;
}
.button {
  color: #ffffff;
  padding: 3px 8px 3px 8px;
}
.logo-image {
  display: inline-block;
  height: auto;
}
.float-right {
  float: right !important;
}
.checkbox-accent-blue {
  accent-color: #088ac8;
  vertical-align: middle;
  height: 16px;
  width: 16px;
}
