/* purgecss ignore */

svg.default-svg .primary {
  fill: #a5b3bb;
}

svg.default-svg .secondary {
  fill: #0d2b3e;
}

.svg-background {
  background-color: #a5b3bb;
}
