/* purgecss ignore */

@import 'third-party/inter/inter.css';

html {
  font-family: 'Inter', sans-serif;
  line-height: 1.15;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('https://fonts.googleapis.com/css?family=Roboto:100') format('ttf');
}

.font-roboto {
  font-family: Roboto, sans-serif;
}

a {
  color: inherit;
  @apply no-underline;
}

.text-icon {
  font-size: 0.5rem;
}

.text-inter-xs {
  font-size: 0.75rem;
  letter-spacing: 0.0077em;
  line-height: 1.05rem;
}

.text-inter-sm {
  font-size: 0.875rem;
  letter-spacing: 0.00043em;
  line-height: 1.225rem;
}

.text-inter-base {
  font-size: 1rem;
  letter-spacing: -0.0047em;
  line-height: 1.4rem;
}

.text-inter-lg {
  font-size: 1.125rem;
  letter-spacing: -0.0083em;
  line-height: 1.575rem;
}

.text-inter-xl {
  font-size: 1.25rem;
  letter-spacing: -0.011em;
  line-height: 1.75rem;
}

.text-inter-2xl {
  font-size: 1.5rem;
  letter-spacing: -0.014em;
  line-height: 2.1rem;
}

.text-inter-3xl {
  font-size: 1.875rem;
  letter-spacing: -0.016em;
  line-height: 2.625rem;
}

.text-inter-4xl {
  font-size: 2.25rem;
  letter-spacing: -0.017em;
  line-height: 3.15rem;
}

.text-inter-5xl {
  font-size: 3rem;
  letter-spacing: -0.017em;
  line-height: 4.2rem;
}

.list-style-number {
  list-style-type: decimal;
  list-style-position: inside;
}

#eventCard p > a {
  @apply break-all text-primary-8 underline;
}

.renewalbe-energy {
  color: var(--main-color-blue-bolt, #007acd);
  font-feature-settings: 'clig' off, 'liga' off;

  /* Body/Base Bold 16px */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.grey-little-text {
  color: var(--gray-600757575, #757575);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  text-transform: uppercase;
}
