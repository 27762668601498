/* purgecss ignore */

.card-project {
  transition: all 0.3s ease-out;
  @apply bg-neutral-0 rounded overflow-hidden shadow-md my-1;
}

.card-project:hover {
  @apply shadow-lg;
}

.card-user {
  transition: all 0.3s ease-out;
  @apply bg-neutral-0 rounded shadow-md my-1;
}

.card-user:hover {
  @apply shadow-lg;
}
