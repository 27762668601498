@import '../../../../styles/fonts/responsivity/breakpoints.scss';

@include extra-large-exluded {
  .ago-navbar-resposive-overwrite nav .nj-navbar--collapse{
    display: none !important;
  }
  .ago-navbar-resposive-overwrite .ago-burger-menu-icons{
    display: flex !important;
  }
}

.ago-header {
  position: sticky;
  top: 0;
  z-index: 9999;
}

.ago-navbar-logo {
  max-width: 8rem;
}

.ago-navbar-link {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.vertical-line-container {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.vertical-line {
  width: 0.063rem;
  height: 3rem;
  opacity: 0.26;
  background: #000;
  margin-left: 2rem;
  margin-right: 2rem;
}

.ago-avatar {
  display: flex;
  align-items: center;
}

.mobile-search,
.mobile-display {
  display: none;
}

.search-item-mobile {
  list-style-type: none;
}

.ago-navbar-dropdown {
  top: 5rem;
}
@include small-and-medium-only {
  .ago-navbar-link {
    font-size: 14px;
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important;
  }
}

@include small-and-medium-only {
  .search-item-mobile {
    list-style-type: none;
  }
  .mobile-search {
    display: flex;
  }

  .mobile-display {
    display: block;
  }

  .hidding-mobile {
    display: none;
  }

  .container-mobile-nav {
    display: flex;
    flex-wrap: wrap;
    align-content: baseline;
    align-items: center;
  }

  .container-mobile-nav > * {
    margin-left: 2rem;
  }
}
