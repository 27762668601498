.ago-burger-menu-content-actions {
  display: flex;
  width: 100%;
  height: 20%;
  justify-content: right;
  align-items: flex-end;
  gap: 2.5rem;
  box-sizing: border-box;
  background-color: white;
}

.ago-burger-menu-content-actions-content {
  display: flex;
  gap: 2.5rem;
  box-sizing: border-box;
  background-color: white;
}

.ago-burger-menu-content-actions-content-profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.938rem;

  &:active {
    color: var(--nj-core-color-blue-600);
  }
}

.ago-burger-menu-content-actions-content-logout {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.625rem;

  &:active {
    color: var(--nj-core-color-blue-600);

    span {
      color: var(--nj-core-color-blue-600);
    }
  }
}
