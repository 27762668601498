@font-face {
  font-family: 'Lato';
  font-style: normal;
  src: 'Lato-Black.ttf' format('truetype');
}

.ago-tera-title {
  color: var(--nj-semantic-color-text-neutral-primary-default);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Lato;
  font-size: 4.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 5.125rem;
}

.ago-sub-tera-title {
  font-family: Lato;
  font-size: 3.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 4rem;
}

.ago-giga-title {
  font-family: Lato;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.813rem;
}

.ago-principal-title-upper-bold {
  font-family: Lato;
  color: var(--nj-semantic-color-text-neutral-primary-default);
  font-style: normal;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.625rem;
  font-weight: 900;
  line-height: 1.75rem;
  text-transform: uppercase;
}
.ago-principal-title-bold {
  font-family: Lato;
  color: var(--nj-semantic-color-text-neutral-primary-default);
  font-style: normal;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.875rem;
}
.ago-principal-title-regular {
  font-family: Lato;
  color: var(--nj-semantic-color-text-neutral-primary-default);
  font-style: normal;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.125rem;
}
.ago-block-title {
  color: var(--nj-semantic-color-text-additional-signature-default);
  text-align: center;
  font-family: var(--nj-semantic-font-family-default);
  font-size: 3.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: var(--nj-semantic-line-height-heading-2xl);
  letter-spacing: var(--nj-semantic-letter-spacing-none);
}
/* h2 */
.ago-subtitle-upper-bold {
  font-family: Lato;
  color: var(--nj-semantic-color-text-neutral-primary-default);
  font-size: 1.25rem;
  font-style: normal;
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 900;
  line-height: 1.75rem;
  text-transform: uppercase;
}
.ago-subtitle-bold {
  font-family: Lato;
  color: var(--nj-semantic-color-text-neutral-primary-default);
  font-size: 1.25rem;
  font-style: normal;
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 700;
  line-height: 1.875rem;
}
.ago-subtitle-regular {
  font-family: Lato;
  color: var(--nj-semantic-color-text-neutral-primary-default);
  font-size: 1.25rem;
  font-style: normal;
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 400;
  line-height: 2.125rem;
}
/* h3 */
.ago-subtitle-h3-upper-bold {
  font-family: Lato;
  color: var(--nj-semantic-color-text-neutral-primary-default);
  font-size: 1.125rem;
  font-style: normal;
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 900;
  line-height: 1.75rem;
  text-transform: uppercase;
}
.ago-subtitle-h3-bold {
  font-family: Lato;
  color: var(--nj-semantic-color-text-neutral-primary-default);
  font-size: 1.125rem;
  font-style: normal;
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 700;
  line-height: 1.875rem;
}
.ago-subtitle-h3-regular {
  font-family: Lato;
  color: var(--nj-semantic-color-text-neutral-primary-default);
  font-size: 1.125rem;
  font-style: normal;
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 400;
  line-height: 2.125rem;
}

/* p */

.ago-text-bold-blue-16 {
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Lato;
  font-size: 1rem;
  font-style: normal;
  color: var(--nj-semantic-color-text-brand-primary-default);
  font-weight: 700;
  line-height: 1.5rem;
}
.ago-text-bold-black-16 {
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Lato;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
}

.ago-text-regular-16 {
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Lato;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}
.ago-big-p-bold {
  color: var(--neutral-colors-gray-600, #60798b);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Lato;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1rem;
}

.ago-big-p-regular-upper {
  color: var(--neutral-colors-gray-600, #60798b);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Lato;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  text-transform: uppercase;
}
.ago-big-p-body {
  color: var(--neutral-colors-gray-600, #60798b);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Lato;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}

.light-blue-900 {
  color: var(--light-blue-blue-900, #0b2d4e);
}
