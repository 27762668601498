.ago-burger-menu-content {
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 1.875rem;
  background-color: white;
  box-sizing: border-box;
}

.ago-burger-menu-content--open {
  animation: menu-open 0.6s;
}

.ago-burger-menu-content--close {
  animation: menu-close 0.6s;
}

@keyframes menu-open {
  0% {
    display: flex;
    transform: translateX(100dvw);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes menu-close {
  0% {
    display: flex;
    transform: translateX(0);
  }
  100% {
    transform: translateX(100dvw);
    display: none;
  }
}
