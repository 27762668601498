.ago-burger-menu-content-items {
  display: flex;
  flex-direction: column;
  height: 80%;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 0.313rem;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background: #b0b0b09c;
    border-radius: 0.625rem;

    &:hover {
      background: #9595959c;
    }
  }
}
