.rich-content div {
  text-align: center;
}

.rich-content img {
  display: inline-block;
}

.rich-content p {
  padding-bottom: 1em;
}

.rich-content ul {
  list-style: inside;
}

.rich-content pre {
  display: block;
  font-family: monospace;
  margin: 1em 0;
  background: #f1f1f1;
  border-radius: 3px;
  padding: 1px 10px;
  white-space: pre-line;
}

.rich-content blockquote {
  border-left: 5px solid #f1f1f1;
  padding-left: 5px;
  margin-left: 20px;
}
