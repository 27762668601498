/* purgecss ignore */

.image-layer .after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  color: #fff;
}
.image-layer:hover .after {
  display: block;
  background: rgba(0, 0, 0, 0.2);
}
.roundedImage {
  max-height: 100px;
  border-radius: 50%;
}

.ago-image-feat-content {
  width: -webkit-fill-available;
}
