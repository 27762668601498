@import '../../../../styles/fonts/responsivity/breakpoints.scss';
.ago-burger-menu-icons {
  display: flex;
  position: absolute;
  background-color: white;
  gap: 1.25rem;

  .ago-burger-menu-icon {
    display: flex;
    background: none;
    border: none;
    align-items: center;
    background-color: transparent;

    &:active {
      span {
        color: var(--nj-core-color-blue-600);
      }
    }
  }

  @include small-special-breakpoint {
    top: 0.938rem;
    right: 0.938rem;
  }

  @include medium-and-large-special-breakpoint {
    top: 2.188rem;
    right: 1.875rem;
  }

  @include large-only {
    display: none;
  }
}
