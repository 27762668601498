/* purgecss ignore */

.common-button {
  color: inherit;
  display: inline-flex;
}

.common-button:focus {
  @apply outline-none;
}

.button-disabled {
  @apply cursor-not-allowed bg-neutral-4 text-neutral-1 font-bold py-2 px-4 rounded;
}

/* default */

.button-default {
  @apply bg-primary-8;
  @apply text-neutral-1 font-bold py-2 px-4 rounded;
}

.button-default:hover {
  @apply bg-primary-10;
}

.button-default:active {
  @apply bg-primary-8;
}

/* neutral */

.button-neutral {
  @apply bg-neutral-5;
  @apply text-neutral-1 font-bold py-2 px-4 rounded;
}

.button-neutral:hover {
  @apply bg-primary-10;
}

.button-neutral:active {
  @apply bg-primary-8;
}

/* info */

.button-info {
  @apply bg-info-8;
  @apply text-neutral-1 font-bold py-2 px-4 rounded;
}

.button-info:hover {
  @apply bg-info-10;
}

.button-info:active {
  @apply bg-info-8;
}

/* warning */

.button-warning {
  @apply bg-warning-8;
  @apply text-neutral-1 font-bold py-2 px-4 rounded;
}

.button-warning:hover {
  @apply bg-warning-10;
}

.button-warning:active {
  @apply bg-warning-8;
}

/* error */

.button-error {
  @apply bg-error-8;
  @apply text-neutral-1 font-bold py-2 px-4 rounded;
}

.button-error:hover {
  @apply bg-error-10;
}

.button-error:active {
  @apply bg-error-8;
}

/* success */

.button-success {
  @apply bg-success-8;
  @apply text-neutral-1 font-bold py-2 px-4 rounded;
}

.button-success:hover {
  @apply bg-success-10;
}

.button-success:active {
  @apply bg-success-8;
}

.contact-button {
  width: 0rem;
}

.contact-button.expanded {
  width: 9rem;
}

/* yellow */

.button-bi-yellow {
  background-color: #f2c813;
  color: #000;
  font-weight: 700;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
}

.button-bi-yellow:hover {
  background-color: #f2c813;
}

.button-bi-yellow:active {
  background-color: #f2c813;
}
